.give-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100vh;
}

.give-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.img1 {
  background-image: linear-gradient(rgba(9, 5, 54, 0.3), rgba(5, 4, 46, 0.7)),
    url('../../img/give3.png');
}
.img2 {
  background-image: linear-gradient(rgba(9, 5, 54, 0.3), rgba(5, 4, 46, 0.7)),
    url('../../img/give4.png');
}

.img1,
.img2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 1.5rem;
  width: 500px;
  height: 400px;
}

.don-btn {
  text-align: center;
  background-color: #008ac4;
  font-size: 1.2rem;
  color: #fff;
  width: 110px;
  padding: 10px;
  border-radius: 5px;
}

/* For the tablet view */
@media screen and (max-width: 992px) {
  .img1,
  .img2 {
    text-align: center;
    width: 370px;
    height: 500px;
  }
}
/* For the mobile View */
@media screen and (max-width: 576px) {
  .give-body {
    gap: 20px;
  }
  .give-cont {
    flex-direction: column;
  }
  .img1,
  .img2 {
    width: 340px;
    height: 250px;
    font-size: 1rem;
  }
}
