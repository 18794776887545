.media > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 30px;
}

.media > div > iframe {
  width: 1000px;
  height: 500px;
}

.media-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #e62117;
  font-size: 1.2rem;
  color: #fff;
  width: 270px;
  padding: 10px;
  border-radius: 5px;
}

/* For the tablet view */
@media screen and (max-width: 992px) {
  .media > div > iframe {
    width: 700px;
  }
}

/* For the mobile View */
@media screen and (max-width: 576px) {
  .media > div > iframe {
    width: 330px;
    height: 200px;
  }
}
/* ImageGallery.css */

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Controls the number of columns */
  gap: 10px; /* No space between images */
  width: 100%;
  max-width: 800px;
  margin: auto;
}


.modal{
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
bottom: 0;
}

.gallery-item {
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  object-fit: cover; /* Ensures images maintain aspect ratio */
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}


/* Style the entire scrollbar */
::-webkit-scrollbar {
  width: 12px;
  background-color: #f1f1f1; /* Background color of the scrollbar */
}

/* Style the scrollbar handle (the draggable part) */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the handle */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Optional: adds space around the handle */
}

/* Change the color of the handle when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Optional: Style the scrollbar track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the track */
  border-radius: 10px;
}


.chat-Container{
  padding: 8px;
}


 

.chat-message{
  display: flex; 
  margin: auto; 
  padding-bottom: 10px;
}

.message_Title{
  font-size: 10px;
  width: 90%;
  padding-left: 10px;
}

.inputchatT{
  width: 96%; 
  margin: auto;

  display: flex;
  justify-content: space-between;
  
}

.inputchatT input{
  width: 90%;
  padding: 8px;
  font-size: 10px;
  outline: none;
  border-radius: 8px;
}


.containerInner:hover .deleteButton{
  transform: translate(0);
}

.deleteButton{
  transform: translateY(-100px);
  padding: 5px;
  background-color: #5555552b;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  font-size: 30px;
}